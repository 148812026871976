<template>
    <div class="fixed cursor-pointer bg-orange hover:bg-orangeDark transition duration-500 flex justify-center items-center rounded-tl-full rounded-bl-full py-4 pl-4 pr-10 right-0 mt-16 z-10" v-on:click.prevent="$emit('close')">
        <svg width="23" height="24" xmlns="http://www.w3.org/2000/svg">
            <g stroke="#FFF" stroke-width="3" fill="none" fill-rule="evenodd" stroke-linecap="square">
                <path d="M20.62 2.385L2.38 21.615M20.51 21.721L2.49 2.279"/>
            </g>
        </svg>
    </div>
</template>

<script>
    export default {}
</script>