<template>
    <div class="py-4 sm:pt-4 pr-4 sm:w-1/3">
        <h3 class="uppercase font-bold text-lg">{{point.title}}</h3>
        <ul class="lowercase font-light text-sm sm:text-base">
            <li>{{point.adresse}}</li>
            <li v-show="point.telephone">{{point.telephone}}</li>
            <li v-show="point.email"><a :href="'mailto:' + point.email">{{point.email}}</a></li>
            <li v-show="point.siteWeb"><a :href="point.siteWeb">{{point.siteWeb}}</a></li>
        </ul>
    </div>
</template>

<script>
    export default {
        props: {
            point: Object
        }
    }
</script>