const gmapElement = $('#googleMap');

function debounce(fn, time) {
    let timeout;
  
    return function() {
      const functionCall = () => fn.apply(this, arguments);
      
      clearTimeout(timeout);
      timeout = setTimeout(functionCall, time);
    }
}

if(gmapElement.length == 1) {
    let map = null;

    let search = $('.search');
    let gmapSearch = $('.search > input[name="q"]');
    let gmapResults = $('.search > .results');
    
    let position = {
        lat: 48.866667,
        lng: 2.333333
    }

    const icon = 'https://recyclage.planeteliege.com/map-picto.svg';

    gmapElement.css({
        width: '100%',
        height: '862px'
    });

    const initMap = () => {
        map = new google.maps.Map(document.getElementById('googleMap'), {
            center: position,
            zoom: 8,
            disableDefaultUI: false,
            styles: [
                {
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        }
                    ]
                },
                {
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#616161"
                        }
                    ]
                },
                {
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        }
                    ]
                },
                {
                    "featureType": "administrative.land_parcel",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#bdbdbd"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#eeeeee"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#757575"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#e5e5e5"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#9e9e9e"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#757575"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#dadada"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#616161"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#9e9e9e"
                        }
                    ]
                },
                {
                    "featureType": "transit.line",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#e5e5e5"
                        }
                    ]
                },
                {
                    "featureType": "transit.station",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#eeeeee"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#c9c9c9"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#9e9e9e"
                        }
                    ]
                }
            ]
        });
        
        if(navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                let pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };

                if(!search.attr('style')) {
                    search.css({
                        top: 0,
                        transform: 'translate(-50%, 0)',
                        transitionDuration:'.5s'
                    });
                }
                
                map.setCenter(pos);
                map.setZoom(12);
            });
        }

       $.ajax('/pointsDeCollecte.json', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json'
            }
        }).done(response => {
            let pointsDeCollectes = response.data;
            let markers = [];

            $.map(pointsDeCollectes, (point, index) => {
                let popup = new google.maps.InfoWindow({
                    content: '<div class="text-center text-kaki max-w-xs">' +
                    '<div class="text-lg font-bold pb-2">' + point.title + '</div>' +
                    '<div class="font-bold">' + point.adresse + '</div>' +
                    ((point.telephone) ? '<div>' + point.telephone + '</div>' : '') +
                    '</div>'
                });
    
                markers[index] = new google.maps.Marker({
                    position: point.position,
                    icon: icon,
                    map: map, 
                });
    
                markers[index].addListener('click', function() {
                    popup.open(map, markers[index]);
                });
            });

            new MarkerClusterer(map, markers, {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
            const loading = document.getElementById('loading');
            loading.style.display = 'none'; 
        });

        let managedCountries = ["FR","BE"];
        gmapSearch.keyup(debounce(() => {
            var alreadyDisplayedAddresses = [];
            $.ajax('https://maps.googleapis.com/maps/api/geocode/json?address=' + gmapSearch.val() + '&key=AIzaSyCSEKwAh0qUsyDhbprbknGJhhUiP1YBz0E').done(function(response) {
                //console.log(response);
                gmapResults.html(null);
                gmapResults.hide();
                if (response.results.length) {
                    gmapResults.show();
                }
                response.results.forEach(city => {
                    var shouldDisplay = false;
                    if(alreadyDisplayedAddresses.indexOf(city.formatted_address) == -1) {
                        city.address_components.forEach(component => {
                            if (component.types.indexOf("country") != -1 && managedCountries.indexOf(component.short_name) != -1) {
                                shouldDisplay = true;
                            }
                        });
                    }
                    if(shouldDisplay) {
                        alreadyDisplayedAddresses.push(city.formatted_address);
                        let a = document.createElement('a');
                        a.dataset.location = JSON.stringify(city.geometry.location);
                        a.text = city.formatted_address;
                        a.addEventListener('click', function (e) {
                            let pos = JSON.parse(e.currentTarget.dataset.location);
                            gmapResults.css({display: 'none'});
                            gmapResults.html(null);
                            gmapSearch.val('');
                            if (!search.attr('style')) {
                                search.css({
                                    top: 0,
                                    transform: 'translate(-50%, 0)',
                                    transitionDuration: '.5s'
                                });
                            }

                            map.setCenter(pos);
                            map.setZoom(12);
                        });

                        gmapResults.append(a);
                    }
                });
            });
        }, 1000));
    }

    initMap();
}
