<template>
    <div class="px-4 pt-4 sm:pt-6 bg-grisClair pb-4" v-if="departement.pointsDeCollecte.length" :id="departement.slug">
         <div class="container mx-auto">
            <div class="py-0 sm:py-6 mentions">
                <h2 class="text-orange text-2-5xl sm:text-5xl font-kanit mb-4">
                    <span class="bg-grisClair pr-2">
                        <i class="not-italic" v-show="departement.numero">
                            {{departement.numero}} - 
                        </i>
                        {{departement.title}}
                    </span>
                </h2>
                <div class="sm:flex sm:justify-start sm:items-start sm:flex-wrap">
                    <point-de-collecte v-for="point in departement.pointsDeCollecte" :key="point.title" :point="point" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import pointDeCollecte from './pointDeCollecteComponent.vue';

    export default {
        components: {
            'point-de-collecte': pointDeCollecte
        },

        props: {
            departement: Object
        }
    }
</script>