<template>
    <div class="container mx-auto relative bg-grisClair flex items-center justify-center pt-6 sm:pt-0 pb-10 sm:pb-0" v-if="departements">
        <div id="departement" class="sm:ml-6 relative sm:absolute z-50 sm:left-0 cursor-pointer sm:-top-40px" v-on:mouseover="hover(true)" v-on:mouseleave="hover(false)">
            <div class="h-12 box-border rounded-full border-3 border-kaki border-solid text-center font-bold text-sm inline-flex px-8 justify-center items-center text-kaki hover:text-white md:text-sm bg-white hover:bg-kaki transition duration-500">Sélectionnez votre département<svg class="ml-1" width="12" height="9" xmlns="http://www.w3.org/2000/svg"><path d="M1 1l4.92 6L11 1.05" class="stroke-current" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round"/></svg></div>
            <div class="bg-kakiClair rounded-lg absolute w-full py-6 overflow-x-none overflow-y-scroll" style="max-height: 200px;" v-show="show">
                <a v-for="departement in departements" :key="departement.id" :href="'/#' + departement.slug" class="px-4 block hover:bg-kaki transition duration-500 hover:text-white" v-on:click="hover(false)">
                    <i class="not-italic" v-show="departement.numero">
                        {{departement.numero}}
                    </i>
                    {{ departement.title }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            departements: null
        },

        data() {
            return {
                show: false
            }
        },

        methods: {
            hover(bool) {
                this.show = bool;
            }
        }
    }
</script>