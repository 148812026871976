<template>
    <div class="fixed z-50 inset-0 bg-kaki overflow-y-scroll text-white" v-if="show">
        <close @close="closePopup" />
        <div class="p-4 container mx-auto flex flex-col justify-start items-start">
            <a href="/" class="w-24 relative z-10">
                <img src="/css/images/logo-recyclage-vert.svg" alt="Logo recyclage bouchon de liège" class="block w-full"/>
            </a>
            <nav class="flex flex-col justify-center items-start text-2xl absolute z-0 inset-0 p-12">
                <a v-for="nav in header" :key="nav.id" :href="nav.url" :class="nav.class" v-on:click="closePopup">{{ nav.title }}</a>
            </nav>
        </div>
    </div>
</template>

<script>
    import close from './snippets/btCloseComponent.vue';

    export default {
        components: {
            close
        },

        data() {
            return {
                header: [],
                show: false
            }
        },

        mounted() {
            fetch(`/header.json`, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json'
                }
            })
            .then(response => {
                return response.json();
            })
            .then(response => {
                this.header = response.data;
            });
        },

        methods: {
            closePopup() {
                this.show = false;
            },

            setShow(val) {
                this.show = val;
            }
        }
    }
</script>

<style lang="scss" scoped>
    nav {
        a {
            height: 43px;
            border-radius: 43px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding: 0 theme('spacing.6');
            margin: theme('spacing.4') 0;

            &:hover, &:active {
                transition-duration: .3s;
                transition-property: background-color;
                background-color: rgba(0, 0, 0, .2);
            }

            &:last-child {
                display: none;
            }
        }
    }
</style>
